<template>
  <div>
    <Modal v-model="importModal" width="450">
      <p slot="header" class="text-center">{{ source === 1 ? '设备' : '资源' }}信息导入</p>
      <div>
        <span>提示：只支持指定模板的单个Excel文件导入。如没有模板，请点击</span><a href="javascript:void(0);"
          @click="handleDownloadTemplate()">模板下载</a>
      </div>
      <div class="m-t-10">
        <Upload ref="upload" :before-upload="handleBeforeUpload" :default-file-list="fileList" :format="['xls', 'xlsx']"
          action="">
          <Button icon="ios-cloud-upload-outline" type="success">选择Excel文件</Button>
        </Upload>
      </div>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="importModal = false">取消</Button>
        <Button type="primary" :disabled="fileList.length === 0" :loading="loading" @click="confirmImport">{{ loading ?
          '上传中...' : '开始上传' }}</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { importExcel } from '@/api/rim/device'
import { imporResourcetExcel } from '@/api/product/resource'
export default {
  props: {
    source: {
      type: Number,
      default: 1, // 1:设备，2：资源
      required: true
    },
    onSuccess: Function
  },
  data () {
    return {
      importModal: false,
      fileList: [],
      loading: false
    }
  },
  methods: {
    show () {
      this.importModal = true
      this.fileList = []
    },
    handleDownloadTemplate () {
      const url = this.source === 1 ? '/ooh-rim/v1/device/downloadtemplate' : '/ooh-product/v1/resource/downloadtemplate'
      console.log(url)
      window.location.href = `${process.env.VUE_APP_API_URL_V2}${url}`
    },
    handleBeforeUpload (file) {
      const types = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
      if (!types.some(x => x === file.type)) {
        this.$Notice.warning({ title: '系统提示', desc: '只支持Excel文件' })
        return false
      }
      this.fileList.push(file)
      return false
    },
    async confirmImport () {
      this.loading = true
      const formData = new FormData()
      this.fileList.forEach(item => {
        formData.append('file', item)
      })
      let result = null
      switch (this.source) {
        case 1: // 设备
          result = await importExcel(formData)
          break
        case 2: // 资源
          result = await imporResourcetExcel(formData)
      }

      this.loading = false
      this.importModal = false
      if (result && result.errcode === 0) {
        this.file = []
        this.$Notice.success({ desc: '上传成功' })
        this.onSuccess()
      } else {
        this.$Notice.error({ desc: result.errmsg })
      }
    }
  }
}
</script>
