<template>
  <div>
    <Form :model="resourceModel" :rules="ruleValidate" ref="resourceModel" label-position="top" autocomplete="on"
      name="oohforce-medium-add" id="oohforce-medium-add">
      <Row :gutter="8" class="m-b-10">
        <i-col span="12">
          <FormItem label="交易资产" prop="assetId">
            <Select v-model="resourceModel.assetId" filterable @on-change="onChangeAsset" size="small"
              placeholder="请选择所属资产（可通过关键字搜索）">
              <Option v-for="item in assetArray" :value="item.id" :key="'asset_' + item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="站点" prop="stationId">
            <Select v-model="resourceModel.stationId" @on-change="handleChangeStation" filterable size="small"
              placeholder="请选择所属站点（可通过关键字搜索）">
              <Option v-for="item in stationArray" :value="item.id" :key="'staiton_' + item.id">
                {{ rimAssetIds.length > 1 ? `${item.name} - ${item.assetName}` : item.name }}</Option>
            </Select>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="12">
          <FormItem label="设备" prop="deviceId">
            <Select v-model="resourceModel.deviceId" filterable size="small" placeholder="请选择设备（可输入关键词搜索）"
              @on-change="handleChangeDevice">
              <Option v-for="item in deviceList" :value="item.id" :key="'device_' + item.id">{{ item.code }}</Option>
            </Select>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="编号" prop="code">
            <Input v-model="resourceModel.code" size="small" placeholder="请填写编号，默认为已选择的设备编号，可编辑" />
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="12">
          <FormItem label="一级类型" prop="resourceBigtypeId">
            <Select v-model="resourceModel.resourceBigtypeId" size="small" placeholder="请选择一级类型"
              @on-change="handleChangeType">
              <Option v-for="item in resourceTypeArray" :value="item.id" :key="'resType_' + item.id">{{ item.name }}
              </Option>
            </Select>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="二级类型" prop="resourcetypeId">
            <Select v-model="resourceModel.resourcetypeId" filterable size="small" placeholder="请选择二级类型（可通过关键字搜索）">
              <Option v-for="item in resChildTypeArray" :value="item.id" :key="'resChildType_' + item.id">{{ item.name }}
              </Option>
            </Select>
          </FormItem>
        </i-col>
        <!-- <i-col span="12">
          <FormItem
            label="是否标准资源"
            prop="standard"
          >
            <Select
              v-model="resourceModel.standard"
              size="small"
              placeholder="请选择是否标准资源"
            >
              <Option :value="1">是</Option>
              <Option :value="0">否</Option>
            </Select>
          </FormItem>
        </i-col> -->
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="12">
          <FormItem label="售卖策略" prop="fix">
            <Select v-model="resourceModel.fix" size="small" placeholder="请选择是否标准资源">
              <Option :value="0">不在主题媒介内</Option>
              <Option :value="1">在主题媒介可单独售卖</Option>
              <Option :value="2">在主题媒介内不可单独售卖</Option>
            </Select>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="面数" prop="side">
            <InputNumber v-model="resourceModel.side" :min="1" style="width:100%" size="small" placeholder="请填写面数" />
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="12">
          <FormItem label="库存量（常规单位为天、电子屏单位为秒）" prop="stock">
            <InputNumber v-model="resourceModel.stock" :min="1" style="width:100%" size="small" placeholder="请填写库存量" />
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="开放市场" prop="open">
            <Select v-model="resourceModel.open" size="small" placeholder="请选择开放市场">
              <Option v-for="item in openArray" :value="item.value" :key="'resOpen_' + item.value">{{ item.name }}
              </Option>
            </Select>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="12">
          <FormItem label="制作尺寸(mm)">
            <Row :gutter="8">
              <i-col span="11">
                <InputNumber v-model="resourceModel.makewidth" :min="0" style="width:100%" size="small" placeholder="宽" />
              </i-col>
              <i-col span="2" class="text-center">*</i-col>
              <i-col span="11">
                <InputNumber v-model="resourceModel.makeheight" :min="0" style="width:100%" size="small"
                  placeholder="高" />
              </i-col>
            </Row>
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="见光尺寸(mm)">
            <Row :gutter="8">
              <i-col span="11">
                <InputNumber v-model="resourceModel.finalwidth" :min="0" style="width:100%" size="small"
                  placeholder="宽" />
              </i-col>
              <i-col span="2" class="text-center">*</i-col>
              <i-col span="11">
                <InputNumber v-model="resourceModel.finalheight" :min="0" style="width:100%" size="small"
                  placeholder="高" />
              </i-col>
            </Row>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="12">
          <FormItem label="面积(㎡)">
            <InputNumber v-model="resourceModel.area" :min="0" style="width:100%" size="small" placeholder="请填写面积" />
          </FormItem>
        </i-col>
        <i-col span="12">
          <FormItem label="等级">
            <Select v-model="resourceModel.level" clearable size="small" placeholder="请选择等级">
              <Option v-for="item in levelArray" :value="item.id" :key="'resLevel_' + item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="12">
          <FormItem label="状态（来自所选设备状态）">
            <RadioGroup v-model="resourceModel.enabled">
              <Radio :disabled="true" :label="1">可用</Radio>
              <Radio :disabled="true" :label="0">不可用</Radio>
              <Radio :disabled="true" :label="-1">冻结</Radio>
            </RadioGroup>
          </FormItem>
        </i-col>
      </Row>
    </Form>
  </div>
</template>

<script>
// import { getDevicePage } from '@/api/product/device'
// import { saveResource, updateResource } from '@/api/product/resource'
/** new api */
import { getRimStationByAssetIds } from '@/api/rim/station'
import { deviceResMixins } from '../mixins/index'
import { getPublisherDevicePage, getDevice } from '@/api/rim/device'
import { submit } from '@/api/product/resource'

export default {
  props: {
    operate: {
      type: Number,
      default: 1
    },
    assetArray: {
      type: Array,
      required: true
    },
    resourceTypeArray: {
      type: Array,
      required: true
    },
    onSuccess: Function
  },
  mixins: [deviceResMixins],
  data () {
    return {
      resourceModel: {
        id: null,
        assetId: null,
        stationId: null,
        deviceId: null,
        code: '',
        resourceBigtypeId: null,
        resourcetypeId: null,
        makewidth: 0,
        makeheight: 0,
        finalwidth: 0,
        finalheight: 0,
        side: 1,
        area: 0,
        enabled: 1,
        fix: 0,
        stock: 1,
        standard: 1,
        floor: null,
        position: null,
        level: null,
        open: 0
        // makeMaterial: null,
        // makeMaterialName: '',
        // makeProcess: null
      },
      rimAssetIds: [],
      deviceQuery: {
        pageNumber: 1,
        pageSize: 1000,
        keyword: '',
        assetId: null,
        devicemodel: null,
        stationId: null,
        enabled: null
      },
      stationArray: [],
      deviceList: [],
      // resourceTypeArray: [],
      resChildTypeArray: [], // 二级类别
      makeMaterialArray: [],
      ruleValidate: {
        assetId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        stationId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        deviceId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        code: [
          { required: true, pattern: '[a-z]|[0-9]', message: ' ', trigger: 'blur' }
        ],
        resourcetypeId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        resourceBigtypeId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        fix: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        // standard: [
        //   { required: true, type: 'number', message: ' ', trigger: 'change' }
        // ],
        side: [
          { required: true, type: 'number', message: ' ', trigger: 'bulr' }
        ],
        stock: [
          { required: true, type: 'number', message: ' ', trigger: 'bulr' }
        ],
        open: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    assetId () {
      return this.$store.state.deviceManage.selectedAssetId
    },
    curResInfo () {
      return this.$store.state.deviceManage.curResInfo
    },
    levelArray () {
      return this.$store.state.deviceManage.levelArray
    },
    curDeviceInfo () {
      return this.$store.state.deviceManage.curDeviceInfo
    }
  },
  // mounted () {
  //   this.getDeviceData()
  // },
  methods: {
    getDeviceData () {
      getPublisherDevicePage(this.deviceQuery).then((res) => {
        if (res && !res.errcode) {
          this.deviceList = res.list
          if (this.operate === 2) { // 编辑时
            this.handleChangeType()
          }
        } else {
          this.deviceList = []
        }
      })
    },
    async getDeviceInfo () {
      const deviceInfo = this.resourceModel.deviceId ? await getDevice({ deviceId: this.resourceModel.deviceId }) : null
      return deviceInfo
    },
    onChangeAsset () {
      this.rimAssetIds = [this.resourceModel.assetId]
      const rimAsset = this.assetArray.find(x => x.id === this.resourceModel.assetId)

      if (rimAsset) {
        this.rimAssetIds = this.assemblyRimAssetIds(rimAsset.rimAssetIds)
      }

      getRimStationByAssetIds({ assetIds: JSON.stringify(this.rimAssetIds) }).then((res) => {
        this.stationArray = res
        // this.$store.commit('set_station_array', this.stationArray)
        if (this.operate === 3) {
          const station = this.stationArray.find(x => x.id === this.resourceModel.stationId)
          this.resourceModel.level = station.level ? station.level : -1
        }
      })
    },
    // // 初始化站点选项
    // async getStationArray () {
    //   // 编辑和从设备添加时获取当前resourceModel的assetId
    //   const assetId = [2, 3].includes(this.operate) ? this.resourceModel.assetId : this.assetId
    //   this.stationArray = await getRimStationByAssetIds({ assetIds: JSON.stringify([assetId]) }) || []
    // },
    handleChangeStation () {
      this.deviceQuery.stationId = this.resourceModel.stationId
      if (this.operate === 1) {
        const station = this.stationArray.find(x => x.id === this.resourceModel.stationId)
        this.resourceModel.level = station.level ? station.level : -1
      }
      this.getDeviceData()
    },
    async handleChangeDevice () {
      if (this.operate !== 3) {
        const deviceInfo = this.resourceModel.deviceId ? await getDevice({ deviceId: this.resourceModel.deviceId }) : null
        if (deviceInfo) {
          this.resourceModel.code = deviceInfo.code
          this.resourceModel.floor = deviceInfo.floor
          this.resourceModel.position = deviceInfo.position
          this.resourceModel.area = deviceInfo.area
          this.resourceModel.makewidth = deviceInfo.makewidth
          this.resourceModel.makeheight = deviceInfo.makeheight
          this.resourceModel.finalwidth = deviceInfo.finalwidth
          this.resourceModel.finalheight = deviceInfo.finalheight
          this.resourceModel.side = deviceInfo.side || 1
          this.resourceModel.enabled = deviceInfo.enabled
        }
      }
    },
    handleChangeType () {
      this.resChildTypeArray = this.resourceTypeArray.find(x => x.id === this.resourceModel.resourceBigtypeId).children || []
    },
    handleSubmit () {
      this.$refs.resourceModel.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确认信息无误，并提交？',
            onOk: () => {
              this.resourceModel.standard = Boolean(this.resourceModel.standard)
              this.resourceModel.level = this.resourceModel.level || -1
              // if (this.operate === 1) {
              // this.resourceModel.assetId = this.stationArray.find(x => x.id === this.resourceModel.stationId).assetId
              // }
              submit(JSON.stringify(this.resourceModel)).then(res => {
                if (res && res.errcode === 0) {
                  this.onSuccess()
                  this.$Notice.success({ desc: '操作成功' })
                }
              })
            }
          })
        } else {
          this.$Notice.error({ desc: '请正确填写信息' })
        }
      })
    }
  },
  watch: {
    operate: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val === 2) { // 编辑
          Object.keys(this.resourceModel).forEach(key => {
            this.resourceModel[key] = this.curResInfo[key]
          })
          this.resourceModel.standard = Number(this.curResInfo.standard)
          // this.getStationArray()
          this.handleChangeStation()
        } else if (val === 3) { // 从设备详情创建
          this.resourceModel.assetId = this.curDeviceInfo.assetId
          this.resourceModel.stationId = this.curDeviceInfo.stationId
          this.resourceModel.deviceId = this.curDeviceInfo.id
          this.resourceModel.code = this.curDeviceInfo.code
          this.resourceModel.side = this.curDeviceInfo.side
          this.resourceModel.area = this.curDeviceInfo.area
          this.resourceModel.makewidth = this.curDeviceInfo.makewidth
          this.resourceModel.makeheight = this.curDeviceInfo.makeheight
          this.resourceModel.finalwidth = this.curDeviceInfo.finalwidth
          this.resourceModel.finalheight = this.curDeviceInfo.finalheight
          // this.resourceModel.open = this.curDeviceInfo.open
          // const station = this.existStationArray.find(x => x.id === this.resourceModel.stationId)
          // this.resourceModel.level = station.level ? station.level : -1
          this.handleChangeStation()
        } else {
          // this.getStationArray()
          this.resourceModel.id = null
        }
        this.onChangeAsset()
      }
    }
  }
}
</script>
