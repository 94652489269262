import { getRimStationByAssetIds } from '@/api/rim/station'
export const deviceResMixins = {
  data () {
    return {
      stationArray: [],
      openArray: [
        { value: 0, name: '不开放二级市场' },
        { value: 1, name: '开放到二级市场' },
        { value: 2, name: '二级市场只能购买临近档期' }
      ]
    }
  },
  methods: {
    getStationData (assetIds) {
      getRimStationByAssetIds({ assetIds: JSON.stringify(assetIds) }).then((res) => {
        this.stationArray = res
        // this.$store.commit('set_station_array', this.stationArray)
      })
    },
    /**
     * 组装rimAssetIds
     * @param {Array} data
     */
    assemblyRimAssetIds (data) {
      const ids = []
      if (data.indexOf(',')) {
        data.split(',').forEach(x => {
          ids.push(parseInt(x))
        })
      } else {
        ids.push(parseInt(data))
      }
      return ids
    }
  }
}
